<div>
    <h2>Filters</h2>

    <div class="mb-3">
        <div class="row">
            <div *ngIf="!isNetworkMember" class="col-md">
                <app-network-member-filter ></app-network-member-filter>
            </div>
            <div class="col-md" *ngIf="isOnIllustration == false">
                <app-users-search [inOverview]="true"></app-users-search>
            </div>
            <div class="col-md">
                <app-clients-search></app-clients-search>
            </div>
            <div class="col-md">
                <app-status-filter></app-status-filter>
            </div>
            <div class="col-md">
                <app-year-filter></app-year-filter>
            </div>
            <div class="col-md" *ngIf="isPaymentsRoute">
                <app-payment-filter></app-payment-filter>
            </div>
          </div>   
    </div>
   
</div>