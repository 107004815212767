import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { CommonService } from 'src/app/services/common/common.service';
import { UserService } from 'src/app/services/user.service';
import { AttachmentDTO, BooleanTaskDto, CommentDto, CommentType } from 'src/app/web-api-client';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from 'src/app/services/comment.service';
import { FileParameter } from 'src/app/web-api-client';
import { AddCommentDto } from 'src/app/models/AddCommentDto';
import { NotificationFrequency } from 'src/app/models/enums/NotificaionFrequency';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/models/enums/NotificationType';

@Component({
  selector: 'app-boolean-task-detail',
  templateUrl: './boolean-task-detail.component.html',
  styleUrls: ['./boolean-task-detail.component.scss']
})

export class BooleanTaskDetailComponent implements OnInit {
  dateTime = new Date()
  // hold the task id
  taskId: string;
  attachments: FileParameter[] = [];
  // hold the task
  task: BooleanTaskDto;
  status: string;
  process: string;
  commentAttachments: AttachmentDTO[] = [];
  // fa icons
  faCheck = faCheck;
  faTimes = faTimes;
  isNetworkMember: boolean;
  isCollaborator: boolean;

  // loading indicator
  loadingTask: boolean = false;
  displayTaskAction: boolean = true;

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp
  gfg = false;
  comments: CommentDto[] = [];

  deletedCommentEventSubscription: Subscription;
  commentAttachmentAddedEventSubscription: Subscription;

  constructor(
    private activatedroute: ActivatedRoute,
    public booleanTaskService: BooleanTaskService,
    public location: Location,
    private userService: UserService,
    private titleService: Title,
    public commonService: CommonService,
    private commentService: CommentService,
    private datePipe: DatePipe
  ) { }

  // oninit method
  async ngOnInit() {
    // loading started
    this.loadingTask = true;

    // get the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    // determine if the user is a network member
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    this.task = this.booleanTaskService.activeBooleanTask;
    this.status = this.commonService.getTaskStatusByNumber(this.task.status.value, true);

    this.displayTaskAction = await this.commonService.displayTaskAction(this.task);

    this.process = this.task.processId;
    this.comments = this.commentService.discussion?.comments?.filter(x => x.isDeleted == false);
    this.commentAttachments = this.getCommentAttachments(this.comments);

    this.deletedCommentEventSubscription = this.commentService.deleteCommentEvent.subscribe((commentId) => { this.filterOutDeletedAttachments(commentId) });
    this.commentAttachmentAddedEventSubscription = this.commentService.addCommentAttachmentEvent.subscribe((attachment) => { this.updateAttachmentsList(attachment) });

    // loading finished
    this.loadingTask = false;

    // set the title
    this.titleService.setTitle(this.task.title + " | Insurope");
  }

  // method to approve the task
  async approveDisaproveTask() {
    // change the status
    let approvedTask = this.task;
    if (approvedTask.value == false && this.isNetworkMember) {
      approvedTask.value = true;

      if (await this.booleanTaskService.changeTaskBooleanStatus(approvedTask)) {
        this.task.value = true;
        var commentDto: AddCommentDto = {
          taskId: this.taskId,
          content: "<p><b>The task has been approved</b></p>",
          processName: this.process,
          clientName: this.task.title,
          networkMemberName: this.task.networkMember.accountName,
          country: null,
          files: null,
          tags: null,
          isDeleted: false,
          isEdited: false,
          isHtmlText: true,
          commentType: CommentType.Comment,
          notificationFrequency: NotificationFrequency.Instantly,
          notificationType: NotificationType.EmailNotification
        }

        await this.commentService.addComment(commentDto);
      }
    }
    else if (approvedTask.value == true && this.isCollaborator) {
      approvedTask.value = false;
      this.task.value = false;
      await this.booleanTaskService.changeTaskBooleanStatus(approvedTask);
    }
  }

  getButtonName() {
    if (this.task.value) {
      if (this.isNetworkMember) {
        return "Approved";
      }
      else {
        return "Click to Disapprove";
      }
    }

    return "Click to Approve";
  }

  // method to reject the task
  async reject() {
    var rejectedTask = this.task;
    rejectedTask.value = false;

    if (await this.booleanTaskService.changeTaskBooleanStatus(rejectedTask)) {
      this.task.value = false;
    }
  }

  getCommentAttachments(comments: CommentDto[]) {
    var attachments = comments?.map(x => x.attachments).flat();
    var sortedAttachemnts = attachments?.sort((a, b) => a.fileDate > b.fileDate ? -1 : 1);
    return sortedAttachemnts;
  }

  getDueDate(booleanTask: BooleanTaskDto) {
    return booleanTask.overdueDate == undefined || booleanTask.overdueDate.getFullYear() == 1 ? "/" : this.datePipe.transform(booleanTask.overdueDate);
  }

  getApprovalDate(booleanTask: BooleanTaskDto) {
    return booleanTask.approvalDate == undefined || booleanTask.approvalDate.getFullYear() == 1 ? "Not approved yet" : this.datePipe.transform(booleanTask.approvalDate);
  }

  formatdate(dateYear: Date) {
    var year = dateYear.getFullYear();
    if (year === 1) {
      return "/";
    }
    return year;
  }

  getMetaData(task: BooleanTaskDto) {
    if (task.sourceId.startsWith("SA")) {
      const parsed = JSON.parse(task.metaData);
      const approvalQuestions = parsed.ApprovalQuestions;

      if (approvalQuestions.length > 0) {
        const periodEndDate = Date.parse(approvalQuestions[0]?.PeriodEndDate);

        if (!isNaN(periodEndDate)) {
          return this.formatdate(new Date(periodEndDate));
        }
        return "";

      }
      else {
        return "";
      }
    }

  }

  filterOutDeletedAttachments(commentId: string) {
    var filteredComments = this.comments.filter(x => x.id != commentId);
    var attachments = this.getCommentAttachments(filteredComments);
    this.commentAttachments = attachments;
  }

  updateAttachmentsList(attachment: AttachmentDTO[]) {
    this.commentAttachments.push(...attachment);
  }

  ngOnDestroy() {
    this.deletedCommentEventSubscription.unsubscribe();
    this.commentAttachmentAddedEventSubscription.unsubscribe();
  }
}