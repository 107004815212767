import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InformativeService } from '../../../../services/informative.service';
import { Location } from '@angular/common';
import { AttachmentDTO, CommentDto, CommentType, FileParameter, GeneralTaskDto, NotificationFrequency, NotificationType } from 'src/app/web-api-client';
import { UserService } from 'src/app/services/user.service';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common/common.service';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CommentService } from 'src/app/services/comment.service';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { ProcessIds } from 'src/app/models/enums/processIds';
import { Subscription } from 'rxjs';
import { forEach } from 'underscore';
import { DocumentService } from 'src/app/services/document.service';
import { AddCommentDto } from 'src/app/models/AddCommentDto';

@Component({
  selector: 'app-informative-detail',
  templateUrl: './informative-detail.component.html',
  styleUrls: ['./informative-detail.component.scss']
})
export class InformativeDetailComponent implements OnInit {
  dateTime = new Date()
  @Input() displaySubTitle: boolean = true;
  commentAttachments: AttachmentDTO[] = [];
  informative: GeneralTaskDto = new GeneralTaskDto();
  taskId: string;
  process:string;
  statusText: string = "";
  isNetworkMember: boolean;
  loadingTask: boolean = false;
  gfg = false;
  comments: CommentDto[] = [];
  isCollaborator: boolean;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp

  deletedCommentEventSubscription: Subscription;
  commentAttachmentAddedEventSubscription: Subscription;

  constructor(public informativeService: InformativeService, private activatedroute: ActivatedRoute,
    public location: Location, private userService: UserService, private titleService: Title, public commonService: CommonService, private commentService: CommentService,private documentService:DocumentService) {
  }

  async ngOnInit() {
    this.loadingTask = true;

    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember)
    this.process = this.informative.processId;
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator)
    this.informative = this.informativeService.activeTask;
    this.statusText = this.commonService.getTaskStatusByNumber(this.informative.status.value);

    this.comments = this.commentService.discussion?.comments?.filter(x => x.isDeleted == false);
    this.commentAttachments = this.getCommentAttachments(this.comments);

    this.titleService.setTitle(this.informative.title + " | Insurope");
    var newDueDate = this.informative.overdueDate;
    this.informative.displayDate = newDueDate;

    this.deletedCommentEventSubscription = this.commentService.deleteCommentEvent.subscribe((commentId) => { this.filterOutDeletedAttachments(commentId) });
    this.commentAttachmentAddedEventSubscription = this.commentService.addCommentAttachmentEvent.subscribe((attachment) => { this.updateAttachmentsList(attachment) });

    this.loadingTask = false;
  }

  getCommentAttachments(comments: CommentDto[]) {
    var attachments: AttachmentDTO[] = [];
    forEach(comments, (comment) => {
      var commentAtt: AttachmentDTO[] = comment.attachments;
      commentAtt.map(att => att.commentId = comment.id);
      attachments.push(...commentAtt);
    });

    var sortedAttachemnts = attachments?.sort((a, b) => a.fileDate > b.fileDate ? -1 : 1);
    return sortedAttachemnts;
  }

  async doneByNm(){
    if ((this.informative.doneByNm == false || this.informative.doneByNm == null) && this.isNetworkMember) {    
          if (await this.informativeService.ChangeDoneByNm(this.taskId,true)) {
            this.informative.doneByNm = true;
            let fileList: FileParameter[] = [];
    
            for (let i = 0; i < this.informative.adminDbAttachments.length; i++) {
              var file = await this.documentService.getAdminDbDocument(this.informative.adminDbAttachments[i], [this.informative.networkMemberId]);
              fileList.push({ data: new Blob([file.data]), fileName: file.fileName })
            }
    
            var commentDto: AddCommentDto = {
              taskId: this.taskId,
              content: "<p><b>The task has been done by NM</b></p>",
              processName: this.process,
              clientName: this.informative.title,
              networkMemberName: this.informative.networkMember.accountName,
              country: null,
              files: fileList,
              tags: null,
              isDeleted: false,
              isEdited: false,
              isHtmlText: true,
              commentType: CommentType.Comment,
              notificationFrequency: NotificationFrequency.Instantly,
              notificationType: NotificationType.EmailNotification
            }
    
            await this.commentService.addComment(commentDto);
          }
        }
        else if (this.informative.doneByNm == true && this.isCollaborator) {
          this.informative.doneByNm = false;
          await this.informativeService.ChangeDoneByNm(this.taskId,false);
        }
  }
  getButtonName() {
    if (this.informative.doneByNm) {
      if (this.isNetworkMember) {
        return "This has been done";
      }
      else {
        return "Click to disapprove";
      }
    }
    return "This has been done";
  }

  getPayToStatus() {
    if (this.informative?.payTo == 2) {
      if (this.informative?.payStartedDate) {
        const formattedDate = new Date(this.informative.payStartedDate).toLocaleDateString();
        return { text: "Confirmation received for local payment on: " + formattedDate + ". Ok to continue with the payment.", class: 'text-success' };
      } else {
        if (this.informative?.processId != ProcessIds.multipoolPayments) { return { text: "! Don't pay till you have received confirmation !", class: 'text-danger' }; }
      }
    }
    return null;
  }

  filterOutDeletedAttachments(commentId: string) {
    var filteredComments = this.comments.filter(x => x.id != commentId);
    var attachments = this.getCommentAttachments(filteredComments);
    this.commentAttachments = attachments;
  }

  updateAttachmentsList(attachment: AttachmentDTO[]) {
    this.commentAttachments.push(...attachment);
  }

  ngOnDestroy() {
    this.deletedCommentEventSubscription.unsubscribe();
    this.commentAttachmentAddedEventSubscription.unsubscribe();
  }
}
