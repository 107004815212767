import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Attachment, CreateIllustrationCommand, CustomFile, IllustrationRequestDto, IllustrationRevisionDto, IllustrationsClient, SubscriberConfigDto, TasksStatus } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root'
})
export class IllustrationService {

  // constructor
  constructor(private illustrationsClient: IllustrationsClient, private router: Router) { }

  // get the illustrations of my organization
  async getMyOrganizationIllustrationsPaginated(clientName: string, status: TasksStatus,subscriberId: string, subscriberEmail:string, dueDate:number, startDate: Date, endDate: Date, pageNumber: number, pageSize: number): Promise<IllustrationRequestDto[]> {
    return await this.illustrationsClient.getMyOrganization(clientName, status, subscriberId, subscriberEmail,dueDate,startDate, endDate, pageNumber, pageSize).toPromise();
  }

  // get the illustrations with networkmemberId
  async getIllustrationsPaginated(networkMemberIds: string[], clientName: string, status: TasksStatus, subscriberId: string, subscriberEmail:string, dueDate : number, startDate: Date, endDate: Date, pageNumber: number, pageSize: number): Promise<IllustrationRequestDto[]> {
    return await this.illustrationsClient.findIllustration(networkMemberIds, clientName, status, subscriberId, subscriberEmail, dueDate, startDate, endDate, pageNumber, pageSize).toPromise();
  }

  // get the illustration by id
  async getIllustrationById(id: string): Promise<IllustrationRequestDto> {
    try {
      // return the illustration
      return await this.illustrationsClient.getIllustrationById(id).toPromise();
    }
    catch (error) {
      this.router.navigate(['/404'], { skipLocationChange: true });
    }
  }

  // method to create an illustration
  async createIllustration(ClientId: string | null,
    ClientName: string | null,
    AdditionalComment: string,
    InsuropeTypeOfBusiness: string,
    CountriesId: string[],
    NetworkMemberId:string,
    Files: CustomFile[],
    InsuropeSubscribers:SubscriberConfigDto[],
    NetworkMemberSubscribers:SubscriberConfigDto[]
    ) {
    // create the command
    let command = new CreateIllustrationCommand();
    command.clientId = ClientId;
    command.clientName = ClientName;
    command.additionalComment = AdditionalComment;
    command.insuropeTypeOfBusiness = InsuropeTypeOfBusiness;
    command.countriesId = CountriesId;
    command.networkMemberId = NetworkMemberId
    command.files = Files;
    command.networkMemberSubscribers = NetworkMemberSubscribers
    command.insuropeSubscribers = InsuropeSubscribers

    
    // create the illustration
    return await this.illustrationsClient.create(command).toPromise();
  }

  async downloadFileFromPath(taskId: string, filePath: string) {
    return await this.illustrationsClient.downloadFileFromPath(taskId, filePath).toPromise();
  }
  
}
